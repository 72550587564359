import Chat from 'twitch-chat-emotes';
import FastAverageColor from 'fast-average-color';

let channels = ['moonmoon'];
const query_vars = {};
const query_parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
	query_vars[key] = value;
});
if (query_vars.channels) {
	channels = query_vars.channels.split(',');
}

const ChatInstance = new Chat({
	channels,
	duplicateEmoteLimit: 1,
	maximumEmoteLimit: 3,
	duplicateEmoteLimit_pleb: 0,
	maximumEmoteLimit_pleb: 1,
	shouldTrackSubs: true
})

const emoteSize = 64;
const emoteSpeed = 1;

const pendingEmoteArray = [];

ChatInstance.on("emotes", (e) => {

	for (let index = 0; index < e.emotes.length; index++) 
	{
		const output = {
			position: getSteamSpawnPosition(),
			emote: e.emotes[index],
			life: makeRandomNear(110, 10),
			opacity: makeRandomNear(0.075, 0.05) * 6,
			size: (0.66 + (Math.random() * 0.33)) * steamW / 2,
			slowDown: makeRandomNear(0.999, 0.001) 
		};
		
		pendingEmoteArray.push(output);
	}
})
const canvas = document.createElement('canvas');
const ctx = canvas.getContext('2d');
ctx.imageSmoothingEnabled = false;

const easeInOutSine = (currentIteration, startValue, changeInValue, totalIterations) => {
	return changeInValue / 2 * (1 - Math.cos(Math.PI * currentIteration / totalIterations)) + startValue;
}

const easeInOutSinePos = (currentIteration, startValue, changeInValue, totalIterations) => {
	return changeInValue / 2 * (1 - Math.abs(Math.cos(Math.PI * currentIteration / totalIterations))) + startValue;
}

const getSpawnPosition = (xVariance, yVariance) => {
	
	let x = -100 + xVariance
	let y = fullHeight + yVariance

	return {x, y};
}

let steamDots = [];

let lightAmount = 1
let lightDirection = true

let dabWidth = 2000 * 0.66;
let dabHeight = 1820 * 0.66;
let dabX = 0;
let dabY = 0;

let steamW = 40;
let steamH = 40;

let fullWidth = window.innerWidth;
let fullHeight = window.innerHeight;

let halfx = window.innerWidth / 2;
let halfy = window.innerHeight / 2;

const dabImg = new Image(dabWidth, dabHeight);
dabImg.src = require('./images/dabNoBack.webp');

const dabLightImg = new Image(dabWidth, dabHeight);
dabLightImg.src = require('./images/JustLight.webp');

const purpleBgImg = new Image(fullWidth, fullHeight);
purpleBgImg.src = require('./images/purple.webp');

const steamImg = new Image(steamW, steamH);
steamImg.src = require('./images/steam.webp');

function approachNumber(num, goal, speed)
{
	if(num > goal - (speed * 2.1) && num < goal + (speed * 2.1))
	{
		return goal
	}
	else if(num < goal)
	{
		num+=speed;
	}
	else if(num > goal)
	{
		num-=speed;
	}
	return num
}

function drawEmote(element)
{
	const emote = element.emote;
	element.position.y -= element.position.ySpeed;
	
	var xSpeedToUse = element.position.xSpeed
	
	if(element.life > 85)
	{
		xSpeedToUse = xSpeedToUse * (element.life * 4) / 100 / 5
	}
	else
	{
		element.position.ySpeed = element.position.ySpeed * element.slowDown
		element.position.xSpeed = element.position.xSpeed * element.slowDown
	}
	
	element.position.x += xSpeedToUse;
	
	if(element.life < 25)
	{
		ctx.globalAlpha = Math.min(element.opacity, (element.life * 4) / 100)
	}
	else
	{
		ctx.globalAlpha = element.opacity
	}
	
	ctx.drawImage(emote.material.canvas, element.position.x, element.position.y, element.size, element.size);
	
	ctx.globalAlpha = 1
	
	element.life -= 0.1;
	
	if(element.life < 0 || element.position.y < -20)
	{
		return true
	}
	return false
}

function makeRandomNear(num, variance)
{
	return (num - variance/2) + Math.random() * variance
}

const getSteamSpawnPosition = () => {
	
	let x = 685 + makeRandomNear(10, 20)
	let y = 648
	
	let xSpeed = makeRandomNear(lightAmount, lightAmount/10) / 2
	let ySpeed = makeRandomNear(0.5, 0.15)
	

	return {x, y, xSpeed, ySpeed};
}

function drawSteamDot(element)
{
	
	element.position.y -= element.position.ySpeed;
	
	var xSpeedToUse = element.position.xSpeed
	
	if(element.life > 85)
	{
		xSpeedToUse = xSpeedToUse * (element.life * 4) / 100 / 5
	}
	else
	{
		element.sizeX += 0.03
		element.sizeY += 0.03
		
		element.position.ySpeed = element.position.ySpeed * element.slowDown
		element.position.xSpeed = element.position.xSpeed * element.slowDown
	}
	
	element.position.x += xSpeedToUse;
	
	if(element.life < 25)
	{
		ctx.globalAlpha = Math.min(element.opacity, (element.life * 4) / 100)
	}
	else
	{
		ctx.globalAlpha = element.opacity
	}
	
	ctx.drawImage(steamImg, element.position.x, element.position.y, element.sizeX, element.sizeY);
	
	ctx.globalAlpha = 1
	
	element.life -= 0.09;
	
	if(element.life < 0 || element.position.y < -20)
	{
		return true
	}
	return false
}

window.addEventListener('DOMContentLoaded', () => {
	function resize() {
		canvas.width = window.innerWidth;
		canvas.height = window.innerHeight;
		fullWidth = window.innerWidth;
		fullHeight = window.innerHeight;
	}
	function init() {
		window.addEventListener('resize', resize)
		document.body.appendChild(canvas);
	}


	function draw() {
		requestAnimationFrame(draw);
		
		ctx.drawImage(purpleBgImg, 0, 0, fullWidth, fullHeight);
		
		let max = lightAmount * 2.5
		for(let i = 0; i < max; i++)
		{
			const newSteam = {
				position: getSteamSpawnPosition(),
				life: makeRandomNear(110, 10),
				opacity: makeRandomNear(0.075, 0.05) * (lightAmount * 1.5),
				sizeX: (0.66 + (Math.random() * 0.33)) * steamW / 2,
				sizeY: (0.66 + (Math.random() * 0.33)) * steamW / 2,
				slowDown: makeRandomNear(0.999, 0.001) 
			};
			
			steamDots.push(newSteam);
		}
		
		for (let index = steamDots.length - 1; index >= 0; index--) 
		{			
			if(drawSteamDot(steamDots[index]))
			{
				steamDots.splice(index, 1);
			}
		}
		
		for (let index = pendingEmoteArray.length - 1; index >= 0; index--) 
		{			
			if(drawEmote(pendingEmoteArray[index]))
			{
				pendingEmoteArray.splice(index, 1);
			}
		}
		
		ctx.drawImage(dabImg, dabX, dabY, dabWidth, dabHeight);
		
		if(lightDirection)
		{
			lightAmount += 0.0075
		}
		else
		{
			lightAmount -= 0.0075
		}
		
		if(lightAmount < 0.25)
		{
			lightDirection = true
		}
		else if(lightAmount > 1)
		{
			lightDirection = false
		}
		else if(Math.random() * 100 < 2.5)
		{
			lightDirection = !lightDirection			
		}		
		
		ctx.globalAlpha = Math.max(0, lightAmount)
		ctx.drawImage(dabLightImg, dabX, dabY, dabWidth, dabHeight);
		ctx.globalAlpha = 1
	}

	resize();

	init();
	draw();
})